import LogoutButton from "@/components/LogoutButton";
import { useCollectionsContext } from "@/contexts/CollectionsContext";
import useCurrentUser from "@/hooks/useCurrentUser";
import usePaywall from "@/hooks/usePaywall";
import {
  CalendarIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  Cog6ToothIcon,
  CreditCardIcon,
  ListBulletIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import clsx from "clsx";
import { observer } from "mobx-react";
import Link from "next/link";
import { useState } from "react";

const buttonClassName =
  "flex h-7 lg:h-9 w-7 lg:w-9 items-center justify-center rounded-full hover:bg-zinc-700";

const itemClassName =
  "flex w-full px-5 py-3 text-sm font-body text-zinc-300 shadow-sm focus-visible:bg-zinc-800 hover:bg-zinc-800 border-b border-zinc-700 focus:outline-none hover:text-zinc-50";

const getInitials = (name: string): string => {
  if (!name) return "";

  // Split the name and take the first and last parts
  const nameParts = name.split(" ").filter((part) => part.length > 0);
  const firstName = nameParts[0] || "";
  const lastName = nameParts[nameParts.length - 1] || "";

  // Get the first letter of the first name and last name
  const firstInitial = firstName.match(/[a-zA-Z]/)?.[0] || "";
  const lastInitial = lastName.match(/[a-zA-Z]/)?.[0] || "";

  // Combine and uppercase the initials
  return (firstInitial + lastInitial).toUpperCase();
};

const AuthMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { isOverlayOpen, setIsOverlayOpen } = useCollectionsContext();
  const { setPaywall } = usePaywall();

  const { currentUser } = useCurrentUser();

  const isMobPlus = currentUser.plan === 2;

  const Icon = isOpen ? ChevronUpIcon : ChevronDownIcon;

  const userInitials = getInitials(
    `${currentUser.firstName} ${currentUser.lastName}`
  );

  const handleSavedRecipesClick = (ev) => {
    if (!isMobPlus) {
      ev.preventDefault();
      setPaywall({
        isOpen: true,
        isClosable: true,
        variant: "saveAndOrganiseRecipes",
      });
      return;
    }

    // Don't actually navigate to /collections
    ev.preventDefault();

    // Toggle the collections overlay
    setIsOverlayOpen(!isOverlayOpen);
  };

  const handlePlannerClick = (ev) => {
    if (!isMobPlus) {
      ev.preventDefault();

      setPaywall({
        isOpen: true,
        isClosable: true,
        variant: "createPersonalisedMealPlans",
      });
      return;
    }
  };

  const MENU_ITEMS = [
    {
      href: "/planner",
      label: "My Planner",
      hideOnDesktop: false,
      plan: 2,
      icon: ListBulletIcon,
      onClick: handlePlannerClick,
    },
    {
      href: "/collections",
      label: "Saved Recipes",
      hideOnDesktop: false,
      onClick: handleSavedRecipesClick,
      plan: 2,
      icon: () => (
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.1948 1.24118C12.0198 1.33718 12.625 2.04893 12.625 2.87993V12.9997L7 11.0547L1.375 12.9997V2.87993C1.375 2.04893 1.9795 1.33718 2.80525 1.24118C5.59239 0.917654 8.40761 0.917654 11.1948 1.24118Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    { href: "/account", label: "Account Settings", icon: Cog6ToothIcon },
    {
      href: process.env.NEXT_PUBLIC_STRIPE_BILLING_PORTAL_URL ?? "#",
      label: "Payments & Invoices",
      icon: CreditCardIcon,
    },
    {
      href: process.env.NEXT_PUBLIC_SUPPORT_URL ?? "https://support.mob.co.uk",
      label: "Support",
      icon: QuestionMarkCircleIcon,
    },
  ];

  return (
    <div className="flex">
      <DropdownMenu.Root onOpenChange={setIsOpen} modal={false}>
        <DropdownMenu.Trigger
          className={clsx(
            "h-component flex items-center space-x-2 rounded-full bg-zinc-200 pb-1 pl-0.5 pr-3 pt-1 transition ease-out hover:bg-zinc-300 lg:pl-0.5"
          )}
          aria-label="Manage Account"
        >
          <div
            className={clsx(
              "flex h-8 w-8 items-center justify-center whitespace-nowrap rounded-full bg-zinc-950 font-body text-sm text-zinc-50 lg:h-9 lg:w-9"
            )}
          >
            {userInitials}
          </div>
          <div className={clsx("flex items-center px-0 text-zinc-950")}>
            <Icon className={clsx("h-3 w-3", {})} />
          </div>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content
            className={clsx(
              "z-[52] overflow-hidden rounded-2xl border-zinc-800 bg-zinc-900 py-1.5 shadow-lg"
            )}
            sideOffset={6}
            collisionPadding={24}
          >
            <DropdownMenu.Group className={clsx("")}>
              {!isMobPlus && (
                <DropdownMenu.Item
                  className={clsx("focus-visible:outline-none")}
                >
                  <Link
                    href="/premium#sign-up"
                    className={clsx(
                      itemClassName,
                      "flex items-center space-x-3 text-sm text-zinc-300"
                    )}
                    onClick={() => {
                      document.dispatchEvent(
                        new KeyboardEvent("keydown", { key: "Escape" })
                      );
                    }}
                  >
                    <div className="flex items-center justify-center gap-3">
                      <div className="flex h-5 w-5 items-center justify-center rounded-full bg-[#04F67B] text-2xs text-zinc-950">
                        {userInitials}
                      </div>
                      <div className="">Free account</div>
                    </div>
                  </Link>
                </DropdownMenu.Item>
              )}

              {isMobPlus && (
                <DropdownMenu.Item
                  className={clsx("focus-visible:outline-none")}
                >
                  <Link href="/account">
                    <div
                      className={clsx(
                        itemClassName,
                        "flex items-center space-x-3 text-sm text-zinc-300"
                      )}
                    >
                      <div className="flex items-center justify-center gap-3">
                        <div className="flex h-5 w-5 items-center justify-center rounded-full bg-purple text-2xs text-white">
                          {userInitials}
                        </div>
                        <div>Premium account</div>
                      </div>
                    </div>
                  </Link>
                </DropdownMenu.Item>
              )}

              {MENU_ITEMS.map((item) => {
                const Icon = item.icon;

                return (
                  <DropdownMenu.Item
                    key={item.href}
                    className={clsx("focus-visible:outline-none", {
                      "lg:hidden": item.hideOnDesktop,
                    })}
                  >
                    <Link
                      href={item.href}
                      className={clsx(
                        itemClassName,
                        "flex items-center space-x-3 text-sm"
                      )}
                      onClick={(ev) => {
                        // If there is a click function for this item, run it
                        if (!!item.onClick) {
                          item.onClick(ev);
                        }

                        document.dispatchEvent(
                          new KeyboardEvent("keydown", { key: "Escape" })
                        );
                      }}
                    >
                      <div
                        className={clsx(
                          "flex h-4 w-4 items-center justify-center"
                        )}
                      >
                        {item.icon && (
                          <Icon className={clsx("h-4 w-4 stroke-[1.5]")} />
                        )}
                      </div>
                      <div>{item.label}</div>
                    </Link>
                  </DropdownMenu.Item>
                );
              })}
              <DropdownMenu.Item className={clsx("focus-visible:outline-none")}>
                <LogoutButton
                  className={clsx(itemClassName, "border-b-0 text-salmon")}
                />
              </DropdownMenu.Item>
            </DropdownMenu.Group>
            <DropdownMenu.Arrow
              className={clsx("fill-zinc-900")}
              width={15}
              height={7}
            />
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </div>
  );
};

const _CollectionsButton = ({ isMobPlus }) => {
  const { setPaywall } = usePaywall();

  const { isOverlayOpen, setIsOverlayOpen } = useCollectionsContext();

  const handleClick = () => {
    if (!isMobPlus) {
      setPaywall({
        isOpen: true,
        isClosable: true,
        variant: "saveAndOrganiseRecipes",
      });
      return;
    }
    setIsOverlayOpen(!isOverlayOpen);
  };

  return (
    <button
      className={clsx(buttonClassName)}
      onClick={handleClick}
      aria-label="Toggle Collections"
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.1948 1.24118C12.0198 1.33718 12.625 2.04893 12.625 2.87993V12.9997L7 11.0547L1.375 12.9997V2.87993C1.375 2.04893 1.9795 1.33718 2.80525 1.24118C5.59239 0.917654 8.40761 0.917654 11.1948 1.24118Z"
          stroke="#D4D4D8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
};

const _PlannerButton = ({ isMobPlus }) => {
  const { setPaywall } = usePaywall();

  if (!isMobPlus) {
    return (
      <button
        className={clsx(buttonClassName)}
        onClick={(e) => {
          e.preventDefault();
          setPaywall({
            isOpen: true,
            isClosable: true,
            variant: "createPersonalisedMealPlans",
          });
        }}
      >
        <CalendarIcon className={clsx("h-[1.1rem] w-[1.1rem] stroke-[1]")} />
      </button>
    );
  }

  return (
    <Link href="/planner" className={clsx(buttonClassName)}>
      <CalendarIcon className={clsx("h-[1.1rem] w-[1.1rem] stroke-[1]")} />
    </Link>
  );
};

export default observer(AuthMenu);
